
import { defineComponent } from 'vue';
import { Category, Item } from '@/service/Cosmic.model';
import { CosmicService } from '@/service/Cosmic.service';

interface Data {
  category?: Category;
  items: Item[];
}

export default defineComponent({
  name: 'Catalog',
  props: ['type'],
  data(): Data {
    return {
      category: undefined,
      items: [],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    type() {
      this.loadItems();
    },
  },
  methods: {
    loadItems() {
      CosmicService.getObject<Category>(this.$props.type)
        .then((category) => {
          this.$data.category = category;
          if (category) {
            CosmicService.getObjects<Item>({ type: 'items', metadata: { category: category._id } })
              .then((items) => {
                this.$data.items = items;
              });
          }
        });
    },
  },
});
